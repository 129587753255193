import { Permission } from '@campxdev/shared'
import {
  Batches,
  Categorys,
  Degrees,
  OrganisationDetails,
  Programs,
  Quotas,
  Staff,
  TipsDetails,
} from 'pages'

export const generalConfigurationRoutes = [
  {
    path: 'organisation',
    element: <OrganisationDetails />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'degrees',
    element: <Degrees />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'staff',
    element: <Staff />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'quotas',
    element: <Quotas />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'batches',
    element: <Batches />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'programs',
    element: <Programs />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
