import { ErrorBoundary, Providers } from '@campxdev/shared'
import { useRoutes } from 'react-router-dom'
import { mainRoutes } from 'routes/main'

const App = () => {
  return (
    <Providers>
      <ErrorBoundary>
        <MainRouter />
      </ErrorBoundary>
    </Providers>
  )
}

export default App

function MainRouter() {
  const app = useRoutes(mainRoutes)
  return app
}
