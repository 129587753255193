import { PageNotFound, withRouteWrapper } from '@campxdev/shared'
import AppLayout from 'layouts/AppLayout'
import { LoginPage } from 'pages/Login'
import SuperAdminLoginPage from 'pages/Login/SuperAdmin/SuperAdminLoginPage'
import { baseRoutes } from './baseRoutes'

export const mainRoutes = [
  {
    path: '/',
    children: [
      {
        element: <AppLayout />,
        children: withRouteWrapper(baseRoutes),
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
