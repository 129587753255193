import { Permission } from '@campxdev/shared'
import { FeeTransactions, FeeTypes } from 'pages'

export const paymentRoutes = [
  {
    path: 'fee-type',
    element: <FeeTypes />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'fee-transactions',
    element: <FeeTransactions />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
