import { Permission } from '@campxdev/shared'
import { BatchConfiguration } from 'pages'

export const batchRoutes = [
  {
    path: ':regBatchId',
    element: <BatchConfiguration />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
