import { Permission } from '@campxdev/shared'
import { LandingPage } from '../pages'
import { batchRoutes } from './batchRoutes'
import { examRoutes } from './examRoutes'
import { generalConfigurationRoutes } from './generalConfiguration'
import { paymentRoutes } from './paymentRoutes'

export const baseRoutes = [
  {
    index: true,
    element: <LandingPage />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'general-config',
    children: generalConfigurationRoutes,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'batch',
    children: batchRoutes,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'payment',
    children: paymentRoutes,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'exam',
    children: examRoutes,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
