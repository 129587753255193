import { Permission } from '@campxdev/shared'
import { Evaluators, ExamRooms } from 'pages'

export const examRoutes = [
  {
    path: 'rooms',
    element: <ExamRooms />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'evaluators',
    element: <Evaluators />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
