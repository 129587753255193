import { lazy } from 'react'

const LandingPage = lazy(() => import('./LandingPage'))
const OrganisationDetails = lazy(
  () => import('./GeneralConfiguration/OrganisationDetails'),
)
const Degrees = lazy(() => import('./GeneralConfiguration/Degrees'))
const Staff = lazy(() => import('./GeneralConfiguration/Staff'))
const Quotas = lazy(() => import('./GeneralConfiguration/Quotas'))
const Batches = lazy(() => import('./GeneralConfiguration/Batches'))
const Programs = lazy(() => import('./GeneralConfiguration/Programs'))
const BatchConfiguration = lazy(
  () => import('./BatchConfiguration/BatchConfiguration'),
)
const FeeTypes = lazy(() => import('./Payments/FeeTypes'))
const FeeTransactions = lazy(() => import('./Payments/Ledger'))
const ExamRooms = lazy(() => import('./Exams/ExamRooms'))
const Evaluators = lazy(() => import('./Exams/Evaluators'))
const Categorys = lazy(() => import('./TenantCreation/Categorys'))
const TipsDetails = lazy(() => import('./TenantCreation/Categorys/TipsDetails'))
export {
  LandingPage,
  OrganisationDetails,
  Degrees,
  Staff,
  Quotas,
  BatchConfiguration,
  FeeTypes,
  FeeTransactions,
  ExamRooms,
  Evaluators,
  Batches,
  Programs,
  Categorys,
  TipsDetails,
}
